<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <div class="flex justify-end gap-2">
        <office-select-search
          :shadow="false"
          v-model="filter.office"
          @change="onFilter"
        />
        <period-select v-model="filter.period_id" @change="onFilter" />
      </div>
      <alert v-if="!filter.office.attributes?.office_id || !filter.period_id" type="warning" message="Stockist belum dipilih" />
      <template v-else>
        <p class="text-right text-sm">
          Saldo Stockist :
          <b>{{
            +meta.grand_total_office_balance | toCurrency
          }}</b>
        </p>
        <datatable :columns="tableColumns">
          <template #tbody="{ classes }">
            <template v-for="(paymentHistory, index) in paymentHistories">
              <tr :key="paymentHistory.id" :class="[classes.tr, 'bg-white']">
                <td :class="classes.td">{{ paymentHistory.created_at | formatDate('DD/MM/YYYY') }}</td>
                <td :class="classes.td">
                  <span v-if="paymentHistory.type !== 'balance'">{{ paymentHistory.order_origin_code }} / {{ paymentHistory.order_destination_code }}</span>
                </td>
                <td :class="classes.td">{{ paymentHistory.payment_code }}</td>
                <td :class="classes.td">{{ paymentHistory.description }}</td>
                <td :class="[classes.td, 'text-right']">
                  <span v-if="paymentHistory.type === 'orders'">-{{ paymentHistory.order_final_price | toCurrency }}</span>
                </td>
                <td :class="[classes.td, 'text-right']">
                  <span v-if="paymentHistory.type === 'balance'">+{{ paymentHistory.payment_amount | toCurrency }}</span>
                  <span v-else-if="paymentHistory.type === 'payments' && !visiblePaymentDetails.includes(index)">+{{ paymentHistory.payment_amount | toCurrency }}</span>
                </td>
                <td :class="[classes.td, 'text-right']">{{ paymentHistory.balance | toCurrency }}</td>
                <td :class="classes.td">
                  <div v-if="paymentHistory.type === 'payments'" class="flex justify-end">
                    <button @click="onToggleDetail(index)">
                      <Icon :icon="visiblePaymentDetails.includes(index) ? 'heroicons:chevron-down' : 'heroicons:chevron-right'" class="w-4 h-4" />
                    </button>
                  </div>
                </td>
              </tr>
              <template v-if="visiblePaymentDetails.includes(index)">
                <tr v-for="(paymentMethod, detailIndex) in paymentHistory.payment_methods" :key="`${index}-method-${detailIndex}`" :class="[classes.tr, 'bg-gray-50']">
                  <td colspan="3"></td>
                  <td :class="classes.td">
                    {{ getPaymentMethodTypeName(paymentMethod) }}
                    {{
                        paymentMethod.officeBank
                        ? ` - ${paymentMethod.officeBank}`
                        : ''
                    }}
                  </td>
                  <td></td>
                  <td :class="[classes.td, 'text-right']">
                    <span v-if="paymentMethod.balance_used === 'order_balance'">({{ paymentMethod.amount | toCurrency }})</span>
                    <span v-else>
                      {{ paymentMethod.amount | toCurrency }}
                    </span>
                  </td>
                </tr>
              </template>
            </template>
            <tr :class="[classes.tr, 'bg-white']">
              <td colspan="3"></td>
              <td :class="classes.td">Total</td>
              <td :class="[classes.td, 'text-right']">{{ +meta.total_order_final_price | toCurrency }}</td>
              <td :class="[classes.td, 'text-right']">{{ +meta.grand_total_payment_amount | toCurrency }}</td>
              <td colspan="2"></td>
            </tr>
          </template>
        </datatable>
      </template>
    </div>
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import Alert from '@/components/base/AlertMessage.vue';
import OfficeSelectSearch from '@/components/office/office-select-search.vue';
import PeriodSelect from '@/components/period/period-select.vue';

export default {
  mixins: [requestMixin],
  components: {
    Alert,
    OfficeSelectSearch,
    PeriodSelect
  },
  data() {
    return {
      visiblePaymentDetails: [],
      currentPeriod: {
        data: {
          attributes: {}
        }
      },
      filter: {
        office: {
          attributes: {
            office_id: null
          }
        },
        period_id: null
      },
      meta: {
        total_order_final_price: 0,
        grand_total_payment_amount: 0,
        grand_total_office_balance: 0
      },
      loadingCurentPeriod: false,
      loadingMeta: false,
      loadingPaymentHistories: false,
      paymentHistories: []
    }
  },
  computed: {
    loading() {
      return this.loadingCurentPeriod || this.loadingPaymentHistories || this.loadingMeta
    },
    tableColumns() {
      return [
        {
          id: 'createdAt',
          name: 'Tanggal',
        },
        {
          id: 'order_code',
          name: 'No Faktur',
        },
        {
          id: 'payment_code',
          name: 'No Invoice',
        },
        {
          id: 'description',
          name: 'Keterangan',
        },
        {
          id: 'final_price',
          name: 'Belanja',
          theadClass: 'text-right',
        },
        {
          id: 'payment_amount',
          name: 'Pembayaran',
          theadClass: 'text-right',
        },
        {
          id: 'balance',
          name: 'Saldo',
          theadClass: 'text-right',
        },
        {
          id: 'collapse',
          name: '',
        },
      ];
    },
  },
  methods: {
    getPaymentMethodTypeName(paymentMethod) {
      if (paymentMethod.type) {
          return paymentMethod.type;
      }

      return {
          order_balance: 'Saldo Pembayaran',
          cashback: 'Cashback'
      }[paymentMethod.balance_used];
    },
    async loadCurrentPeriod() {
      this.loadingCurentPeriod = true

      const [res, err] = await this.request('/api/v1/periods/-actions/get-current-period')

      if (!err) {
        this.currentPeriod = res

        this.filter.period_id = res.data.id
      }

      this.loadingCurentPeriod = false
    },
    async loadMeta() {
      this.loadingMeta = true

      const [res, err] = await this.request('/api/v1/offices/-actions/get-order-payment-report-meta', {
        params: {
          'filter[office_uuid]': this.filter.office.attributes?.office_id,
          'filter[period_uuid]': this.filter.period_id
        }
      })

      if (!err) {
        this.meta = res
      }

      this.loadingMeta = false
    },
    async loadPaymentReport() {
      this.loadingPaymentHistories = true

      const [res, err] = await this.request('/api/v1/offices/-actions/get-order-payment-history', {
        params: {
          'filter[office_uuid]': this.filter.office.attributes?.office_id,
          'filter[period_uuid]': this.filter.period_id
        }
      })

      if (!err) {
        this.paymentHistories = res
      }

      this.loadingPaymentHistories = false
    },
    onFilter() {
      this.loadMeta()
      this.loadPaymentReport()
    },
    onToggleDetail(index) {
      if (this.visiblePaymentDetails.includes(index)) {
        this.visiblePaymentDetails.splice(this.visiblePaymentDetails.indexOf(index), 1)
      } else {
        this.visiblePaymentDetails.push(index)
      }
    }
  },
  async created() {
    await this.loadCurrentPeriod()
  }
};
</script>
