<template>
  <div>
    <datatable
      :columns="tableColumns"
      :total="paymentHistories.meta.page.total"
      :perPage="paymentHistories.meta.page.perPage"
      :currentPage="paymentHistories.meta.page.currentPage"
      @pagechanged="onChangePage"
    >
      <template #tbody="{ classes }">
        <tr
          v-for="paymentHistory in paymentHistories.data"
          :key="paymentHistory.id"
          :class="[classes.tr, 'bg-white']"
        >
          <td :class="classes.td">
            {{ paymentHistory.attributes.createdAt | formatDate }}
          </td>
          <td :class="classes.td">
            {{ paymentHistory.attributes.payment_method_type }}
          </td>
          <td :class="classes.td">
            {{ paymentHistory.attributes.description }}
          </td>
          <td :class="[classes.td, 'text-right']">
            <p v-if="paymentHistory.attributes.amount < 1">-</p>
            <p v-else>{{ paymentHistory.attributes.amount | toCurrency }}</p>
          </td>
          <td :class="[classes.td, 'text-right']">
            <p v-if="paymentHistory.attributes.amount < 1">
              {{ Math.abs(paymentHistory.attributes.amount) | toCurrency }}
            </p>
            <p v-else>-</p>
          </td>
        </tr>
      </template>
    </datatable>

    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';

export default {
  mixins: [requestMixin],
  data() {
    return {
      loading: false,
      paymentHistories: {
        meta: {
          page: {},
        },
        data: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    tableColumns() {
      return [
        { id: 'date', name: 'Tanggal' },
        { id: 'method', name: 'Metode Pembayaran' },
        { id: 'description', name: 'Keterangan' },
        { id: 'debit', name: 'Debit', theadClass: 'text-right' },
        { id: 'credit', name: 'Kredit', theadClass: 'text-right' },
      ];
    },
  },
  methods: {
    async loadPaymentHistories(params) {
      this.loading = true;

      const [res, err] = await this.request(
        `/api/v1/offices/${this.me.office_id}/payment-histories`,
        {
          params: {
            'page[size]': 10,
            sort: '-id',
            ...params,
          },
        }
      );

      if (!err) {
        this.paymentHistories = res;
      }

      this.loading = false;
    },
    onChangePage(page) {
      this.loadPaymentHistories({
        'page[number]': page,
      });
    },
  },
  created() {
    this.loadPaymentHistories();
  },
};
</script>
