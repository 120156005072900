<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <div class="flex justify-end gap-2">
        <period-select
          v-model="filter.period_id"
          @change="loadPaymentReports"
        />
      </div>
      <datatable
        :columns="tableColumns"
        :data="paymentReports.data.data"
        :total="paymentReports.data.total"
        :per-page="page.size"
        :current-page="page.number"
        @pagechanged="onChangePage"
      >
        <template #tbody="{ classes }">
          <tr
            v-for="paymentReport in paymentReports.data.data"
            :key="paymentReport.id"
            :class="[classes.tr, 'bg-white']"
          >
            <td :class="classes.td">{{ paymentReport.office_code }}</td>
            <td :class="classes.td">{{ paymentReport.office_name }}</td>
            <td :class="[classes.td, 'text-right']">
              {{ +paymentReport.sum_order_final_price | toCurrency }}
            </td>
            <td :class="[classes.td, 'text-right']">
              {{ +paymentReport.sum_payment_amount | toCurrency }}
            </td>
            <td :class="[classes.td, 'text-right']">
              {{ +paymentReport.office_balance | toCurrency }}
            </td>
          </tr>

          <tr v-if="paymentReports.data.data.length">
            <td></td>
            <td :class="[classes.td]">Total</td>
            <td :class="[classes.td, 'text-right']">
              {{ +paymentReports.meta.total_order_final_price | toCurrency }}
            </td>
            <td :class="[classes.td, 'text-right']">
              {{ +paymentReports.meta.total_payment_amount | toCurrency }}
            </td>
            <td :class="[classes.td, 'text-right']">
              {{ +paymentReports.meta.total_office_balance | toCurrency }}
            </td>
          </tr>
        </template>
      </datatable>
    </div>
  </base-wrapper>
</template>

<script>
import PeriodSelect from '@/components/period/period-select.vue';
import { requestMixin } from '@/mixins/request/request';

export default {
  mixins: [requestMixin],
  components: { PeriodSelect },
  data() {
    return {
      currentPeriod: {
        data: {
          attributes: {},
        },
      },
      filter: {
        period_id: null,
      },
      loadingPaymentReports: false,
      loadingCurentPeriod: false,
      page: {
        size: 10,
        number: 1,
      },
      paymentReports: {
        data: {
          data: [],
        },
        meta: {},
      },
    };
  },
  computed: {
    loading() {
      return this.loadingCurentPeriod || this.loadingPaymentReports;
    },
    tableColumns() {
      return [
        {
          id: 'office_code',
          name: 'Stockist',
        },
        {
          id: 'office_name',
          name: 'Pengelola Stockist',
        },
        {
          id: 'sum_order_final_price',
          name: 'Belanja',
          theadClass: 'text-right',
        },
        {
          id: 'sum_payment_amount',
          name: 'Pembayaran',
          theadClass: 'text-right',
        },
        {
          id: 'office_balance',
          name: 'Saldo',
          theadClass: 'text-right',
        },
      ];
    },
  },
  methods: {
    async loadCurrentPeriod() {
      this.loadingCurentPeriod = true;

      const [res, err] = await this.request(
        '/api/v1/periods/-actions/get-current-period'
      );

      if (!err) {
        this.currentPeriod = res;

        this.filter.period_id = res.data.id;
      }

      this.loadingCurentPeriod = false;
    },
    async loadPaymentReports() {
      this.loadingPaymentReports = true;

      const [res, err] = await this.request(
        '/api/v1/offices/-actions/get-order-payment-report',
        {
          params: {
            'page[size]': this.page.size,
            'page[number]': this.page.number,
            'filter[period_uuid]': this.filter.period_id,
          },
        }
      );

      if (!err) {
        this.paymentReports = res;
      }

      this.loadingPaymentReports = false;
    },
    onChangePage(page) {
      this.page.number = page;

      this.loadPaymentReports();
    },
  },
  async created() {
    await this.loadCurrentPeriod();
    this.loadPaymentReports();
  },
};
</script>
