<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <div class="flex justify-end gap-2">
        <period-select v-model="filter.period_id" @change="loadJournalEntries" />
        <base-button>
        <Icon icon="heroicons:arrow-down-tray-solid" class="h-4 w-4" />
          Download GL
        </base-button>
      </div>
      <datatable
        :columns="tableColumns"
        :total="journalEntries.meta.page.total"
        :perPage="journalEntries.meta.page.perPage"
        :currentPage="journalEntries.meta.page.currentPage"
        @pagechanged="onChangePage"
      >
        <template #tbody="{ classes }">
          <tr v-for="journalEntry in journalEntries.data" :key="journalEntry.id" :class="[classes.tr, 'bg-white']">
            <td :class="classes.td">{{ journalEntry.attributes.payment_code }}</td>
            <td :class="classes.td">{{ journalEntry.attributes.order_origin_code }}</td>
            <td :class="classes.td">{{ journalEntry.attributes.createdAt | formatDate }}</td>
            <td :class="classes.td">{{ journalEntry.attributes.accno }}</td>
            <td :class="classes.td">{{ journalEntry.attributes.cost_center }}</td>
            <td :class="[classes.td, 'text-right']">
              <span v-if="!journalEntry.attributes.debit_amount">-</span>
              <span v-else>{{ journalEntry.attributes.debit_amount | toCurrency }}</span>
            </td>
            <td :class="[classes.td, 'text-right']">
              <span v-if="!journalEntry.attributes.credit_amount">-</span>
              <span v-else>{{ journalEntry.attributes.credit_amount | toCurrency }}</span>
            </td>
            <td :class="classes.td">{{ journalEntry.attributes.description || '-' }}</td>
            <td :class="classes.td">{{ journalEntry.attributes.angsuran || '-' }}</td>
          </tr>
        </template>
      </datatable>
    </div>
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import PeriodSelect from '@/components/period/period-select.vue';

export default {
  mixins: [requestMixin],
  components: { PeriodSelect },
  data() {
    return {
      currentPeriod: {
        data: {
          attributes: {}
        }
      },
      filter: {
        period_id: null
      },
      loadingCurentPeriod: false,
      loadingJournalEntries: false,
      journalEntries: {
        meta: {
          page: {}
        },
        data: []
      }
    }
  },
  computed: {
    loading() {
      return this.loadingCurentPeriod || this.loadingJournalEntries
    },
    tableColumns() {
      return [
        {
          id: 'payment_code',
          name: 'NO',
        },
        {
          id: 'order_origin_code',
          name: 'VOURCHERNO',
        },
        {
          id: 'createdAt',
          name: 'DATE',
        },
        {
          id: 'accno',
          name: 'ACCNO',
        },
        {
          id: 'cost_center',
          name: 'COSTCENTRE',
        },
        {
          id: 'debit_amount',
          name: 'DBAMOUNT',
          theadClass: 'text-right',
        },
        {
          id: 'credit_amount',
          name: 'CRAMOUNT',
          theadClass: 'text-right',
        },
        {
          id: 'description',
          name: 'DESCRIPTION',
        },
        {
          id: 'angsuran',
          name: 'ANGSUR',
        },
      ]
    }
  },
  methods: {
    async loadCurrentPeriod() {
      this.loadingCurentPeriod = true

      const [res, err] = await this.request('/api/v1/periods/-actions/get-current-period')

      if (!err) {
        this.currentPeriod = res

        this.filter.period_id = res.data.id
      }

      this.loadingCurentPeriod = false
    },
    async loadJournalEntries(params) {
      this.loadingJournalEntries = true

      const [res, err] = await this.request('/api/v1/journal-entries', {
        params: {
          'sort': 'native-id',
          'page[size]': 10,
          'filter[period_uuid]': this.filter.period_id,
          ...params
        }
      })

      if (!err) {
        this.journalEntries = res
      }

      this.loadingJournalEntries = false
    },
    onChangePage(page) {
      this.loadJournalEntries({
        'page[number]': page
      })
    }
  },
  async created() {
    await this.loadCurrentPeriod()
    this.loadJournalEntries()
  }
}
</script>