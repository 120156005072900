<template>
  <div class="flex gap-2">
    <base-select
      :shadow="false"
      :options="years"
      v-model="year"
      @change="onChangeYear"
    />
    <base-select
      :shadow="false"
      :options="months"
      v-model="month"
      @change="$emit('change')"
    />
  </div>
</template>

<script>
import { getListPastNYear } from '@/services/date.service.js';
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';

export default {
  mixins: [requestMixin],
  props: {
    value: String
  },
  emits: ['input', 'change'],
  data() {
    return {
      year: new Date().getFullYear(),
      periods: {
        data: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/getUser',
    }),
    month: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    months() {
      return [
        {
          key: 'placeholder',
          label: 'Periode Bulan',
          value: null,
          disabled: true,
        },
        ...this.periods.data.map((month) => ({
          key: month.attributes.name,
          label: month.attributes.name.split(' ')[0],
          value: month.id,
        })),
      ];
    },
    years() {
      return [
        {
          key: 'placeholder',
          label: 'Periode Tahun',
          value: null,
          disabled: true,
        },
        ...getListPastNYear(10).map((year) => ({
          key: year,
          label: year,
          value: year,
        })),
      ];
    },
  },
  methods: {
    async loadPeriods() {
      const [res, err] = await this.request(`/api/v1/periods`, {
        params: {
          'filter[year]': this.year,
        },
      });

      if (!err) {
        this.periods = res;
      }
    },
    async onChangeYear() {
      await this.loadPeriods();

      if (this.periods.data.length) {
        this.month = this.periods.data[0].id;

        this.$emit('change');
      } else {
        this.month = null;
      }
    },
  },
  async mounted() {
    this.year = new Date().getFullYear()

    await this.loadPeriods();
  },
};
</script>
