<template>
  <base-wrapper>
    <payment-history-list />
  </base-wrapper>
</template>

<script>
import PaymentHistoryList from '@/components/payment/payment-history-list.vue';

export default {
  components: {
    PaymentHistoryList,
  },
};
</script>
