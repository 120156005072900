<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <div class="flex justify-end gap-2">
        <base-input
          label="Dari"
          type="date"
          :shadow="false"
          v-model="filter.from_date"
          @change="onFilter"
        />
        <base-input
          label="Sampai"
          type="date"
          :shadow="false"
          v-model="filter.to_date"
          @change="onFilter"
        />
        <base-input label="Metode Pembayaran" :shadow="false">
          <base-select
            :shadow="false"
            :options="filterTypeOptions"
            expand
            v-model="filter.payment_method_type_id"
            @change="onFilter"
          />
        </base-input>
        <base-input label="Pilih Kantor" :shadow="false">
          <office-select-search
            :shadow="false"
            v-model="filter.office"
            @change="onFilter"
          />
        </base-input>
        <base-button>
          <Icon icon="heroicons:arrow-down-tray-solid" class="h-4 w-4" />
        </base-button>
      </div>
      <datatable
        :columns="tableColumns"
        :total="payments.meta.page.total"
        :perPage="payments.meta.page.perPage"
        :currentPage="payments.meta.page.currentPage"
        @pagechanged="onChangePage"
      >
        <template #tbody="{ classes }">
          <template v-for="payment in payments.data">
            <tr :key="payment.id" :class="[classes.tr, 'bg-white']">
              <td :class="classes.td">
                <p class="font-bold text-gray-900">
                  {{ payment.attributes.code }}
                </p>
              </td>
              <td :class="classes.td">
                {{
                  getSingleIncluded(
                    payments,
                    payment.relationships.order.data.id
                  ).attributes.origin_code
                }}
                /
                {{
                  getSingleIncluded(
                    payments,
                    payment.relationships.order.data.id
                  ).attributes.destination_code
                }}
              </td>
              <td :class="classes.td">
                {{ payment.attributes.updatedAt | formatDate }}
              </td>
              <td :class="classes.td">
                {{
                  getSingleIncluded(
                    payments,
                    payment.relationships['destination-office'].data.id
                  ).attributes.code
                }}
              </td>
              <td :class="[classes.td, 'text-right']">
                {{
                  getSingleIncluded(
                    payments,
                    payment.relationships.order.data.id
                  ).attributes.grand_total_price | toCurrency
                }}
              </td>
              <td :class="[classes.td, 'text-right']">
                {{ payment.attributes.total_amount | toCurrency }}
              </td>
              <td :class="classes.td">
                {{ payment.attributes.description ?? '-' }}
              </td>
              <td :class="[classes.td, 'text-right']">
                <div class="flex items-center justify-end">
                  <button
                    class="flex items-center justify-end"
                    @click="onClickDetail(payment)"
                  >
                    <Icon
                      :icon="
                        detailPayment.id === payment.id
                          ? 'heroicons:chevron-down'
                          : 'heroicons:chevron-right'
                      "
                      class="h-4 w-4"
                    />
                  </button>
                </div>
              </td>
            </tr>
            <template v-if="detailPayment.id === payment.id">
              <tr
                v-for="paymentMethod in detailPayment.paymentMethods.data"
                :key="`${payment.id}-${paymentMethod.id}`"
                :class="[classes.tr, 'bg-gray-50']"
              >
                <td colspan="3"></td>
                <td :class="classes.td">
                  {{ paymentMethod.attributes.payment_method_type }}
                  <span v-if="paymentMethod.attributes.office_bank_name">
                    - {{ paymentMethod.attributes.office_bank_name }}</span
                  >
                </td>
                <td></td>
                <td :class="[classes.td, 'text-right']">
                  {{ paymentMethod.attributes.payment_amount | toCurrency }}
                </td>
              </tr>
            </template>
          </template>
          <tr v-if="payments.data.length" :class="[classes.tr, 'bg-white']">
            <td colspan="3"></td>
            <td :class="classes.td">Nilai Total</td>
            <td :class="[classes.td, 'text-right']">
              {{ total.total_grand_total_price | toCurrency }}
            </td>
            <td :class="[classes.td, 'text-right']">
              {{ total.total_amount | toCurrency }}
            </td>
            <td colspan="2"></td>
          </tr>
        </template>
      </datatable>
    </div>
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';
import OfficeSelectSearch from '@/components/office/office-select-search.vue';
import dayjs from 'dayjs';

export default {
  mixins: [requestMixin],
  components: {
    OfficeSelectSearch,
  },
  data() {
    return {
      detailPayment: {
        id: null,
        paymentMethods: {
          data: [],
        },
      },
      filter: {
        from_date: new Date(),
        to_date: new Date(),
        payment_method_type_id: null,
        office: {
          attributes: {},
        },
      },
      loadingPayments: false,
      loadingPaymentMethods: false,
      loadingPaymentMethodTypes: false,
      loadingTotal: false,
      payments: {
        meta: {
          page: {},
        },
        data: [],
      },
      paymentMethodTypes: {
        data: [],
      },
      total: {
        total_grand_total_price: 1000,
        total_amount: 500,
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    filterTypeOptions() {
      return [
      {
          key: 'all',
          label: 'Semua',
          value: null,
        },
        ...this.paymentMethodTypes.data.map((type) => ({
          key: type.id,
          label: type.attributes.name,
          value: type.id,
        })),
      ]
    },
    loading() {
      return (
        this.loadingPayments ||
        this.loadingPaymentMethods ||
        this.loadingTotal ||
        this.loadingPaymentMethodTypes
      );
    },
    tableColumns() {
      return [
        {
          id: 'code',
          name: 'Nomor Invoice',
        },
        {
          id: 'order_code',
          name: 'Faktur',
        },
        {
          id: 'date',
          name: 'Tanggal',
        },
        {
          id: 'office',
          name: 'Stockist',
        },
        {
          id: 'price',
          name: 'Total Jual',
          theadClass: 'text-right',
        },
        {
          id: 'paid',
          name: 'Jumlah',
          theadClass: 'text-right',
        },
        {
          id: 'description',
          name: 'Keterangan',
        },
        {
          id: 'action',
          name: '',
          theadClass: 'text-right',
        },
      ];
    },
  },
  methods: {
    async loadPayments(params) {
      this.loadingPayments = true;

      const [res, err] = await this.request('/api/v1/payments', {
        params: {
          include: 'order,destination-office',
          'fields[payments]':
            'code,updatedAt,total_amount,description,order,destination-office',
          'fields[orders]': 'origin_code,destination_code,grand_total_price',
          'fields[offices]': 'code',
          'page[size]': 5,
          sort: 'createdAt',
          'filter[from_date]': dayjs(this.filter.from_date)
            .startOf('day')
            .toISOString(),
          'filter[to_date]': dayjs(this.filter.to_date)
            .endOf('day')
            .toISOString(),
          'filter[payment_method_type_uuid]':
            this.filter.payment_method_type_id,
          'filter[destination_office_uuid]':
            this.filter.office?.attributes?.office_id,
          'filter[is_verified]': true,
          ...params,
        },
      });

      if (!err) {
        this.payments = res;
      }

      this.loadingPayments = false;
    },
    async loadPaymentMethods() {
      this.loadingPaymentMethods = true;

      const [res, err] = await this.request(
        `/api/v1/payments/${this.detailPayment.id}/payment-methods`
      );

      if (!err) {
        this.detailPayment.paymentMethods = res;
      }

      this.loadingPaymentMethods = false;
    },
    async loadPaymentMethodTypes() {
      this.loadingPaymentMethodTypes = true;

      const [res, err] = await this.request(
        `/api/v1/offices/${this.me.office_id}/payment-method-types`
      );

      if (!err) {
        this.paymentMethodTypes = res;
      }

      this.loadingPaymentMethodTypes = false;
    },
    async loadTotal() {
      this.loadingTotal = true;

      const [res, err] = await this.request(
        '/api/v1/payments/-actions/get-report-meta',
        {
          params: {
            'filter[origin_office_uuid]': this.me.office_id,
            'filter[from_date]': dayjs(this.filter.from_date)
              .startOf('day')
              .toISOString(),
            'filter[to_date]': dayjs(this.filter.to_date)
              .endOf('day')
              .toISOString(),
            'filter[payment_method_type_uuid]':
              this.filter.payment_method_type_id,
            'filter[destination_office_uuid]':
              this.filter.office?.attributes?.office_id,
            'filter[is_verified]': 1,
          },
        }
      );

      if (!err) {
        this.total = res;
      }

      this.loadingTotal = false;
    },
    onChangePage(page) {
      this.detailPayment.id = null;

      this.loadPayments({
        'page[number]': page,
      });
    },
    onClickDetail(payment) {
      if (this.detailPayment.id === payment.id) {
        this.detailPayment.id = null;
      } else {
        this.detailPayment.id = payment.id;

        this.loadPaymentMethods();
      }
    },
    onFilter() {
      this.detailPayment.id = null;

      this.loadPayments();
      this.loadTotal();
    },
  },
  created() {
    this.loadPayments();
    this.loadTotal();
    this.loadPaymentMethodTypes();
  },
};
</script>
